import React from "react"
import {useDispatch, useSelector} from "react-redux"
import {Button} from "rsuite"
import {selectCombinedCrDr, toggleCombinedCrDr} from "../../store/entries-slice"

export default function ToggleCombinedCrDr() {
    const dispatch = useDispatch()
    const combinedCrDr = useSelector(selectCombinedCrDr)

    return (
        <Button size="xs" appearance="subtle" active={combinedCrDr}
                onClick={() => dispatch(toggleCombinedCrDr())}>
            S/H
        </Button>
    )
}
