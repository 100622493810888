import {useContext} from "react"
import {useDispatch, useSelector} from "react-redux"
import {DateRangePicker, Dropdown, InputGroup, Popover, Whisper} from "rsuite"
import ArrowDownIcon from "@rsuite/icons/ArrowDown"
import AureliaContext from "../../../utilities/aurelia-context"
import {selectDate, selectDateRange, selectOrganization, setDate, setDateRange} from "../../store/entries-slice"
import {selectCurrentPeriod, selectPeriod} from "../../store/state-slice"
import useAccountingStyles from "../../styles"

const today = new Date()
today.setHours(0, 0, 0, 0)

const ranges = [
    {
        label: 'dieser Monat',
        placement: 'left',
        value: [
            new Date(today.getFullYear(), today.getMonth(), 1),
            today
        ]
    },
    {
        label: 'letzter Monat',
        placement: 'left',
        value: [
            new Date(today.getFullYear(), today.getMonth() - 1, 1),
            new Date(today.getFullYear(), today.getMonth(), 0)
        ]
    },
    {
        label: 'letzte 30 Tage',
        placement: 'left',
        value: [
            new Date(today.getFullYear(), today.getMonth() - 1, today.getDate() - 31),
            new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)
        ]
    },
    {
        label: 'dieses Jahr',
        placement: 'left',
        value: [
            new Date(today.getFullYear(), 0, 1),
            new Date(today.getFullYear(), 11, 31)
        ]
    },
    {
        label: 'letztes Jahr',
        placement: 'left',
        value: [
            new Date(today.getFullYear() - 1, 0, 1),
            new Date(today.getFullYear() - 1, 11, 31)
        ]
    },
    {
        label: 'vorletztes Jahr',
        placement: 'left',
        value: [
            new Date(today.getFullYear() - 2, 0, 1),
            new Date(today.getFullYear() - 2, 11, 31)
        ]
    }
]


export default function DateRange() {
    const dispatch = useDispatch()
    const {i18n} = useContext(AureliaContext)
    const organization = useSelector(selectOrganization)
    const period = useSelector(selectCurrentPeriod(organization))
    const {periodStart, periodEnd} = useSelector(selectPeriod(period)) ?? {}
    const date = useSelector(selectDate)
    const [valueStart, valueEnd] = useSelector(selectDateRange)
    const {dateRange} = useAccountingStyles()
    const labels = {
        bookDate: i18n.tr("accounting.ledger-account.table.bookDate"),
        receiptDate: i18n.tr("accounting.ledger-account.table.receiptDate")
    }

    const renderDateRangeMenu = ({onClose, left, top, className}, ref) => {
        const handleSelect = date => {
            onClose()
            dispatch(setDate(date))
        }

        return (
            <Popover ref={ref} className={className} style={{left, top}} full>
                <Dropdown.Menu size="sm" onSelect={handleSelect}>
                    {Object.entries(labels).map(([key, label]) => (
                        <Dropdown.Item key={key} eventKey={key}>{label}</Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Popover>
        )
    }

    return (
        <InputGroup size="sm">
            <Whisper trigger="click" placement="autoVertical" speaker={renderDateRangeMenu}>
                <InputGroup.Button size="sm">
                    <small>{labels[date]}</small>
                    <ArrowDownIcon/>
                </InputGroup.Button>
            </Whisper>

            <DateRangePicker
                className={dateRange}
                isoWeek={true}
                size="sm"
                appearance="subtle"
                showOneCalendar
                value={[
                    valueStart ?? periodStart ?? new Date(today.getFullYear(), 0, 1),
                    valueEnd ?? periodEnd ?? new Date(today.getFullYear(), 11, 31)
                ]}
                showWeekNumbers
                cleanable
                character=" — "
                format="dd.MM.yyyy"
                placeholder="(ganzes Jahr)"
                ranges={ranges}
                onChange={v => dispatch(setDateRange(v))}
            />

        </InputGroup>
    )
}
